export const languages = [
  {
    title: "English",
    isoCode: "en",
    icon: `<svg
        width="800px"
        height="800px"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        className="iconify iconify--twemoji"
        preserveAspectRatio="xMidYMid meet"
        {...props}
      >
        <path
          fill="#00247D"
          d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"
        />
        <path
          fill="#CF1B2B"
          d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"
        />
        <path
          fill="#EEE"
          d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"
        />
        <path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z" />
      </svg>`,
  },
  {
    title: "Spanish",
    isoCode: "es",
    icon: `<?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width="96.000000pt" height="96.000000pt" viewBox="0 0 96.000000 96.000000"
     preserveAspectRatio="xMidYMid meet">
    
    <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M40 700 l0 -80 440 0 440 0 0 80 0 80 -440 0 -440 0 0 -80z"/>
    <path d="M240 545 c0 -12 13 -15 60 -15 47 0 60 3 60 15 0 12 -13 15 -60 15
    -47 0 -60 -3 -60 -15z"/>
    <path d="M195 520 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2 0
    -7 -4 -10 -10z"/>
    <path d="M253 513 c-7 -2 -13 -13 -13 -24 0 -14 -7 -19 -30 -19 -16 0 -30 -5
    -30 -10 0 -6 11 -8 25 -4 25 6 28 1 37 -61 3 -17 12 -20 56 -22 42 -1 54 2 62
    17 16 30 12 50 -11 50 -42 0 -69 63 -31 73 9 3 1 5 -18 5 -19 0 -41 -2 -47 -5z"/>
    <path d="M388 513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
    <path d="M385 460 c28 -12 37 -12 30 0 -3 6 -16 10 -28 9 -21 0 -21 -1 -2 -9z"/>
    <path d="M196 434 c-11 -7 -17 -16 -15 -19 3 -3 14 2 25 9 10 8 17 17 14 19
    -3 3 -14 -1 -24 -9z"/>
    <path d="M380 440 c0 -5 9 -14 20 -20 11 -6 20 -7 20 -3 0 5 -9 14 -20 21 -11
    7 -20 8 -20 2z"/>
    <path d="M195 370 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
    <path d="M385 370 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
    <path d="M40 260 l0 -80 440 0 440 0 0 80 0 80 -440 0 -440 0 0 -80z"/>
    </g>
    </svg>
    `,
  },
  {
    title: "French",
    isoCode: "fr",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 356.18"><path fill="#E1000F" d="M345.04 0h139C499.44.1 512 12.72 512 28.13v299.91c0 15.47-12.65 28.13-28.13 28.14H345.04V0zM15.11 352.95zm-9.54-8.15z"/><path fill="#fff" d="M27.96 0h317.08v356.18H27.98C12.57 356.09 0 343.46 0 328.04V28.14C0 12.72 12.56.1 27.96 0z"/><path fill="#273375" d="M27.96 0h138.99v356.18H28c-15.42-.08-28-12.71-28-28.14V28.14C0 12.72 12.56.1 27.96 0z"/></svg>`,
  },
];
