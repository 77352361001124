import { PortableText } from "@portabletext/react";
import React from "react";
import FooterLinkItem from "./Footer/FooterLinkItem.jsx";
import Logo from "./logo/Logo";

const Footer = ({ data, isSubmitted }) => {
  return (
    <div className="footer p-4 sm:p-10">
      <div className="cc-container">
        <div className="flex flex-col lg:flex-row mb-6 md:mb-[100px]">
          <div className="">
            <div className="">
              <div className="">
                <Logo image={data?.logo} />
              </div>

              {isSubmitted && (
                <div className="mt-8">
                  {data?.contact.map((item, index) =>
                    item.inputType === "link" ? (
                      <p
                        key={index}
                        className="hover:text-primary py-1"
                        style={{
                          color: data?.contactColor?.hex,
                        }}
                      >
                        <a href={item.link}>{item.value}</a>
                      </p>
                    ) : (
                      <p
                        key={index}
                        className="py-1"
                        style={{
                          color: data?.contactColor?.hex,
                        }}
                      >
                        {item.value}
                      </p>
                    )
                  )}
                </div>
              )}
              {isSubmitted && (
                <div className="flex items-center mt-6">
                  {data?.social.map((item, index) => (
                    <a
                      href={item?.url}
                      key={index}
                      aria-label="link"
                      className="pr-2"
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: item.icon }}
                        className="flex justify-center"
                      />
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>

          {isSubmitted &&
            data?.menu &&
            data.menu.map((single, key) => {
              return (
                <div
                  key={key}
                  className="w-full lg:ml-[70px] xl:ml-[100px] mt-6"
                >
                  <FooterLinkItem
                    data={single}
                    key={key}
                    headingClr={data?.headingColor}
                    linkClr={data?.linksColor}
                  />
                </div>
              );
            })}
        </div>

        <div className="">
          <div className="border-t-2 border-t-assent py-4">
            <div className="text-secondary text-center whitespace-pre-wrap">
              {data?._rawCc && <PortableText value={data?._rawCc} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
