import React, { Fragment, useEffect, useState } from "react";
import NavLink from "../../NavLink";
import Logo from "../logo/Logo";
import { languages } from "./NavBar/Languages";

function FlagIcon({ countryCode = "" }) {
  if (countryCode === "en") {
    countryCode = "us";
  }

  return (
    <span
      className={`fi fis w-4 h-4 text-base rounded-full border-none bg-transparent inline-block mr-2 fi-${countryCode}`}
    />
  );
}
const NavHeader = ({ data, isSubmitted }) => {
  const [open, setOpen] = useState(false);
  const selectedLanguage = languages.find(
    (language) => language.isoCode === process.env.GATSBY_SANITY_LANG
  );
  useEffect(() => {
    if (open) {
      const menuDiv = document.getElementById("inner");
      menuDiv.style.transform = "translateX(0px)";
    } else {
      const menuDiv = document.getElementById("inner");
      menuDiv.style.transform = "translateX(-800px)";
    }
  }, [open]);

  return (
    <Fragment>
      <div className={`mobile-header`}>
        <div className="flex items-center justify-between px-4 py-7">
          <div className="flex -mt-6">
            <Logo image={data?.logo} />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="black"
            viewBox="0 0 16 16"
            onClick={() => setOpen(true)}
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </div>

        <div
          className={`mobile-menu transition-all duration-400 ${
            open ? "visible" : "invisible"
          }`}
        >
          <div className="inner" id="inner">
            <div className="flex items-center justify-between px-4 py-2">
              <div className="flex items-center">
                <Logo image={data?.logo} />
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="black"
                viewBox="0 0 16 16"
                onClick={() => setOpen(false)}
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
            {isSubmitted && (
              <ul className="pl-7 pt-4">
                {data?.menu?.map((item, index) => (
                  <div>
                    {(item?.childMenu?.length === 0 ||
                      item.link !== item.childMenu[0]?.link) && (
                      <li
                        key={index}
                        className="list-none pt-2"
                        onClick={() => setOpen(false)}
                        style={{
                          color: data?.menuColor?.hex ?? "#7C7C7C",
                        }}
                      >
                        <NavLink
                          type={item.type}
                          link={item.link}
                          label={item.label}
                        />
                      </li>
                    )}
                    {item?.childMenu?.length > 0 && (
                      <ul className="">
                        {item.childMenu.map((child, ind) => {
                          return (
                            <li
                              key={ind}
                              className="list-none pt-2"
                              onClick={() => setOpen(false)}
                              style={{
                                color: data?.menuColor?.hex ?? "#7C7C7C",
                              }}
                            >
                              <NavLink
                                type={child.type}
                                link={child.link}
                                label={child.label}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                ))}
                <div className="relative inline-block text-left">
                  <div
                    className=" mt-2 w-40"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="language-selector"
                  >
                    <div className="py-1 flex gap-2" role="none">
                      {languages.map((language, index) => {
                        return (
                          <a
                            key={language.isoCode}
                            href={
                              language.isoCode === "en"
                                ? `https://thenewinverrary.com${window?.location?.pathname}`
                                : `https://${language.isoCode}.thenewinverrary.com${window?.location?.pathname}`
                            }
                            className={`${
                              selectedLanguage.isoCode === language.isoCode
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } block px-2 py-1 text-xs text-left items-center inline-flex hover:bg-gray-100 ${
                              index % 2 === 0 ? "rounded-r" : "rounded-l"
                            }`}
                            role="menuitem"
                          >
                            <FlagIcon countryCode={language.isoCode} />
                            <span className="truncate">{language.title}</span>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </ul>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NavHeader;
