import React from "react";
import { Link } from "gatsby";

export default function NavLink({ type, link, label, clr }) {
  if (type === "internal")
    return (
      <Link
        to={String(link).startsWith("/") ? link : `/${link}`}
        style={{
          color: clr?.hex,
        }}
      >
        {" "}
        <span className="menu-text text-inherit">{label}</span>
      </Link>
    );
  else
    return (
      <a
        href={link}
        target="_blank"
        style={{
          color: clr?.hex,
        }}
      >
        <span className="menu-text text-inherit">{label}</span>
      </a>
    );
}
