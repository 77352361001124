import React, { useState } from "react";
import NavLink from "../../../NavLink";
import { languages } from "./Languages";

function FlagIcon({ countryCode = "" }) {
  if (countryCode === "en") {
    countryCode = "us";
  }

  return (
    <span
      className={`fi fis w-6 h-6 text-2xl rounded-full border-none bg-transparent inline-block mr-2 fi-${countryCode}`}
    />
  );
}
const NavBar = ({ list, menuClr }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedLanguage = languages.find(
    (language) => language.isoCode === process.env.GATSBY_SANITY_LANG
  );

  return (
    <nav className="flex items-center">
      <ul className="flex items-center">
        {list?.map((item, index) => (
          <li
            key={index}
            className="relative group pr-3 flex items-center lg:pr-[20px] xl:pr-[50px] transition-colors duration-300 hover:text-primary text-sm  2xl:text-[20px] font-normal 2xl:leading-[30px]"
            style={{
              color: menuClr?.hex,
            }}
          >
            <NavLink type={item.type} link={item.link} label={item.label} />
            {item?.childMenu?.length > 0 && (
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 2xl:ml-[19px]"
              >
                <path
                  d="M9 1L5 5L1 1"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {item?.childMenu?.length > 0 && (
              <ul className="absolute transition-all rounded-xl border border-e4 -translate-x-6 invisible opacity-0 group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 left-0 top-7 2xl:top-8 bg-white py-2 px-2 min-w-[200px] shadow-[rgba(0,0,0,0.08)_0_1px_2px_0]">
                {item.childMenu.map((child, ind) => {
                  return (
                    <li
                      key={ind}
                      className="relative flex items-center text-sm 2xl:text-base leading-6 2xl:leading-[30px] font-normal"
                    >
                      <NavLink
                        type={child.type}
                        link={child.link}
                        label={child.label}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <div className="relative inline-block text-left">
        <div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            aria-haspopup="true"
            aria-expanded={isOpen}
          >
            <FlagIcon countryCode={selectedLanguage.isoCode} />
            {selectedLanguage.title}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {isOpen && (
          <div
            className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="language-selector"
          >
            <div className="py-1 flex flex-col gap-2" role="none">
              {languages.map((language, index) => {
                return (
                  <a
                    key={language.isoCode}
                    href={
                      language.isoCode === "en"
                        ? `https://thenewinverrary.com${window?.location?.pathname}`
                        : `https://${language.isoCode}.thenewinverrary.com${window?.location?.pathname}`
                    }
                    className={`${
                      selectedLanguage.isoCode === language.isoCode
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-700"
                    } block px-4 py-2 text-sm text-left items-center inline-flex hover:bg-gray-100 ${
                      index % 2 === 0 ? "rounded-r" : "rounded-l"
                    }`}
                    role="menuitem"
                  >
                    <FlagIcon countryCode={language.isoCode} />
                    <span className="truncate">{language.title}</span>
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
