import PropTypes from "prop-types";
import React from "react";
import NavLink from "../../NavLink";

const FooterLinkItem = ({ data, headingClr, linkClr }) => {
  return (
    <>
      <h4
        className="font-bold text-base"
        style={{
          color: headingClr?.hex,
        }}
      >
        {data?.heading}
      </h4>
      <div className="">
        <ul>
          {data?.menu?.map((single, key) => (
            <li
              key={key}
              className="py-1"
              style={{
                color: linkClr?.hex,
              }}
            >
              <NavLink
                type={single.type}
                link={single.link}
                label={single.label}
                clr={linkClr}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

FooterLinkItem.propTypes = {
  data: PropTypes.object,
};

export default FooterLinkItem;
