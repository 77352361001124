import AOS from "aos";
import "aos/dist/aos.css";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import ScrollToTop from "../ScrollToTop";
import Footer from "./Footer";
import Header from "./header";

const OPTIONS = {
  threshold: 0,
  rootMargin: "0px 0px -250px 0px",
};

export default function Layout({ children }) {
  const layoutData = useStaticQuery(
    graphql`
      query {
        en: sanitySitesettings(language: { eq: "en" }) {
          header {
            logo {
              alt
              asset {
                url
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            menuColor {
              hex
            }
            menu {
              label
              link
              type
              childMenu {
                label
                link
                type
              }
            }
          }
          footer {
            logo {
              alt
              asset {
                url
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            headingColor {
              hex
            }
            linksColor {
              hex
            }
            menu {
              heading
              menu {
                label
                link
                type
              }
            }
            social {
              icon
              name
              url
            }
            contactColor {
              hex
            }
            contact {
              value
              link
              inputType
            }
            _rawCc
          }
        }
        es: sanitySitesettings(language: { eq: "es" }) {
          header {
            logo {
              alt
              asset {
                url
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            menuColor {
              hex
            }
            menu {
              label
              link
              type
              childMenu {
                label
                link
                type
              }
            }
          }
          footer {
            logo {
              alt
              asset {
                url
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            headingColor {
              hex
            }
            linksColor {
              hex
            }
            menu {
              heading
              menu {
                label
                link
                type
              }
            }
            social {
              icon
              name
              url
            }
            contactColor {
              hex
            }
            contact {
              value
              link
              inputType
            }
            _rawCc
          }
        }
        fr: sanitySitesettings(language: { eq: "fr" }) {
          header {
            logo {
              alt
              asset {
                url
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            menuColor {
              hex
            }
            menu {
              label
              link
              type
              childMenu {
                label
                link
                type
              }
            }
          }
          footer {
            logo {
              alt
              asset {
                url
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            headingColor {
              hex
            }
            linksColor {
              hex
            }
            menu {
              heading
              menu {
                label
                link
                type
              }
            }
            social {
              icon
              name
              url
            }
            contactColor {
              hex
            }
            contact {
              value
              link
              inputType
            }
            _rawCc
          }
        }
      }
    `
  );

  const lng = process.env.GATSBY_SANITY_LANG;
  const [isSubmitted, setSubmitted] = useState(false);
  useEffect(() => {
    AOS.init();
    if (lng === "en") {
      setSubmitted(localStorage.getItem("thenewinverrary-signup"));
    } else {
      setSubmitted(true);
    }
  }, []);

  return (
    <>
      <Header data={layoutData[lng]?.header} isSubmitted={isSubmitted} />
      <main className="overflow-x-hidden bg-white">{children}</main>
      <ScrollToTop color={layoutData[lng]?.scroll} />

      <Footer data={layoutData[lng]?.footer} isSubmitted={isSubmitted} />
    </>
  );
}
