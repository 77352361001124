import React from "react";
import { useWindowSize } from "../../../hooks/windowSize";
import NavDesktop from "./DesktopNav";
import NavMobile from "./MobileNav";

export default function Navbar({ data, isSubmitted }) {
  const { width } = useWindowSize();

  if (width === 0) return <></>;
  return width > 990 ? (
    <NavDesktop data={data} isSubmitted={isSubmitted} />
  ) : (
    <NavMobile data={data} isSubmitted={isSubmitted} />
  );
}
