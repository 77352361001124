import React, { Fragment, useEffect, useState } from "react";
import Logo from "../logo/Logo";
import NavBar from "./NavBar/NavBar";

const DeskHeader = ({ data, isSubmitted }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  useEffect(() => {
    const header = document.querySelector(".header-section");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  // ${ scroll > headerTop ? "is-sticky" : url === "/" ? "" : "is-sticky" }

  return (
    <Fragment>
      <div
        className={`header-section bg-transparent sticky-header section is-sticky`}
      >
        <div className="header-inner px-[50px] 2xl:px-[82px] pt-[30px] 2xl:pt-[48px]">
          <div className="cc-container relative">
            <div className="flex items-center">
              <div>
                <Logo image={data?.logo} />
              </div>
              {isSubmitted && (
                <div className="ml-5 lg:ml-[80px] ">
                  <NavBar list={data?.menu} menuClr={data?.menuColor} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DeskHeader;
